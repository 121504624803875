<template>
  <div id="companyManagement" class="container">
    <div class="group group-100 title-form">
      Informazioni azienda
    </div>
    <form class="scroll" @submit.prevent="">
      <label class="files-container-label">Logo & Cover</label>
      <div class="files-container">
        <!-- Logo Upload Section -->
        <div class="logo-container">
          <canvas ref="canvasLogo" class="logo-canvas" width="128" height="128" @click.prevent="triggerFileUpload('inputLogo')" />
          <input id="inputLogo" type="file" accept=".jpg,.png,.bmp" class="file-input" @change="uploadLogo($event)">
          <div class="uploadLogo" @click.prevent="triggerFileUpload('inputLogo')">
            <span />
          </div>
        </div>

        <!-- Cover Upload Section -->
        <div id="cover-container" class="cover-container">
          <canvas ref="canvasCover" class="cover-canvas" width="946" height="326" @click.prevent="triggerFileUpload('inputCover')" />
          <input id="inputCover" type="file" accept=".jpg,.png,.bmp" class="file-input" @change="uploadCover($event)">
          <div class="uploadCover" @click.prevent="triggerFileUpload('inputCover')">
            <span />
          </div>
        </div>
      </div>
      <!-- Form fields for company details -->
      <div class="group group-100">
        <input id="co_name" v-model="accountData.co_name" autocomplete="new-password" type="text" class="inputMaterial">
        <span class="bar" />
        <label>Ragione sociale *</label>
        <p v-show="(checked === 1) && !accountData.co_name" class="error-input">
          Campo obbligatorio.
        </p>
        <p v-show="(checked === 2) && accountData.co_name" class="error-input">
          Questo nome è già utilizzato da un'altra azienda. Per favore, scegli un nome diverso.
        </p>
      </div>
      <div class="group group-100">
        <textarea id="co_description" v-model="accountData.co_description" rows="5" autocomplete="new-password" class="inputMaterial" />
        <span class="bar" />
        <label>Descrizione *</label>
        <p v-show="(checked === 1) && !accountData.co_description" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <v-select
          v-model="accountData.country"
          class="inputMaterial"
          :options="countries"
          filterable
          :clearable="false"
          label="label"
          :reduce="country => country.value"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'country'"
          @option:selected="selectCountry"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'country' }" />
        <label>Paese *</label>
        <p v-show="(checked === 1) && !accountData.country" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <v-select
          v-if="accountData.country === 'Italia'"
          v-model="accountData.region_state"
          class="inputMaterial"
          :options="regions"
          filterable
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'region'"
        />
        <input
          v-else
          id="region_state"
          v-model="accountData.region_state"
          autocomplete="new-password"
          type="text"
          class="inputMaterial"
        >
        <span class="bar" />
        <label>Regione *</label>
        <p v-show="(checked === 1) && !accountData.region" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <input id="city" v-model="accountData.city" autocomplete="new-password" type="text" class="inputMaterial">
        <span class="bar" />
        <label>Città *</label>
        <p v-show="(checked === 1) && !accountData.city" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <input id="postal_code" v-model="accountData.postal_code" autocomplete="new-password" type="text" class="inputMaterial">
        <span class="bar" />
        <label>Cap *</label>
        <p v-show="(checked === 1) && !accountData.postal_code" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <input id="address" v-model="accountData.address" autocomplete="new-password" type="text" class="inputMaterial">
        <span class="bar" />
        <label>Indirizzo *</label>
        <p v-show="(checked === 1) && !accountData.address" class="error-input">
          Campo obbligatorio.
        </p>
      </div>

      <div class="group group-50">
        <v-select
          v-model="accountData.locale"
          :clearable="false"
          class="inputMaterial"
          :options="languages"
          filterable
          label="label"
          :reduce="locale =>locale.value"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'locale'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'locale' }" />
        <label>Lingua *</label>
        <p v-show="(checked === 1) && !accountData.locale" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <input
          id="website"
          v-model="accountData.website"
          autocomplete="new-password"
          type="text"
          class="inputMaterial"
          @blur="validateUrls(accountData.website, 'website')"
        >
        <span class="bar" />
        <label>Website *</label>
        <p v-show="(checked === 1) && !accountData.website" class="error-input">
          Campo obbligatorio.
        </p>
        <p v-show="checkUrls.website" class="error-text">
          {{ checkUrls.website }}
        </p>
      </div>
      <div class="group group-50">
        <input
          id="corporate_video_url"
          v-model="accountData.corporate_video_url"
          autocomplete="new-password"
          type="text"
          class="inputMaterial"
          @blur="validateUrls(accountData.corporate_video_url, 'corporate_video_url')"
        >
        <span class="bar" />
        <label>Corporate Video</label>
        <p v-show="checkUrls.corporate_video_url" class="error-text">
          {{ checkUrls.corporate_video_url }}
        </p>
      </div>
      <div class="group group-50">
        <input id="privacy_policy_url" v-model="accountData.privacy_policy_url" autocomplete="new-password" type="text" class="inputMaterial">
        <span class="bar" />
        <label>Privacy Policy</label>
      </div>
      <!-- Team -->
      <div class="group group-100 title-form">
        Team
      </div>
      <div class="group group-50">
        <input id="team_name" v-model="accountData.team_name" autocomplete="new-password" type="text" class="inputMaterial">
        <span class="bar" />
        <label>Nome del team *</label>
        <p v-show="(checked === 1) && !accountData.team_name" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <input
          id="email"
          v-model="accountData.email"
          autocomplete="new-password"
          type="email"
          class="inputMaterial"
          @blur="validateEmail(accountData.email, 'email')"
        >
        <span class="bar" />
        <label>E-mail dell'admin *</label>
        <p v-show="checkEmails.email" class="error-text">
          {{ checkEmails.email }}
        </p>
        <p v-show="(checked === 1) && !accountData.email" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <input id="rfirst_name" v-model="accountData.first_name" autocomplete="new-password" type="text" class="inputMaterial">
        <span class="bar" />
        <label>Nome dell'admin *</label>
        <p v-show="(checked === 1) && !accountData.first_name" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <div class="group group-50">
        <input id="last_name" v-model="accountData.last_name" autocomplete="new-password" type="text" class="inputMaterial">
        <span class="bar" />
        <label>Cognome dell'admin *</label>
        <p v-show="(checked === 1) && !accountData.last_name" class="error-input">
          Campo obbligatorio.
        </p>
      </div>
      <!-- Contract and discount fields -->
      <div class="group group-100 title-form">
        Contratto
      </div>
      <div class="group group-50">
        <v-select
          v-model="accountData.contracttype"
          :clearable="false"
          class="inputMaterial"
          :options="contracts"
          filterable
          label="label"
          :reduce="contracttype => contracttype.value"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'contracttype'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'contracttype' }" />
        <label>Contratto *</label>
      </div>
      <div class="group group-50">
        <v-select
          v-model="accountData.unlock_discount_reserve"
          :clearable="false"
          class="inputMaterial"
          :options="coupons"
          filterable
          :reduce="unlock_discount_reserve => unlock_discount_reserve.value"
          label="label"
          @option:selected="accountData.unlock_discount_reserve = $event.value"
          @search:blur="activeBar = false"
          @search:focus="activeBar = 'unlock_discount_reserve'"
        />
        <span class="bar" :class="{ activeBar: activeBar === 'unlock_discount_reserve' }" />
        <label>Scontistica *</label>
      </div>

      <div class="group group-50">
        <material-date-picker
          label="Data inizio contratto"
          :bordered="true"
          :required="false"
          :date="startDate"
          clearable="true"
          @update:date="startDate = $event"
        />
      </div>

      <div class="group group-50">
        <material-date-picker
          label="Data fine contratto"
          :bordered="true"
          :required="false"
          :date="endDate"
          clearable="true"
          @update:date="endDate = $event"
        />
      </div>

      <span class="mt-50">Email alert fine contratto (14gg dalla scadenza)</span>
      <div class="group group-100">
        <Multiselect
          v-model="accountData.contract_alert_emails"
          mode="tags"
          :can-clear="true"
          :close-on-select="true"
          :clear-on-select="true"
          :clear-on-blur="true"
          :searchable="true"
          :create-option="true"
          :options="tags"
          placeholder="Email"
          no-options-text="Inserisci un'e-mail premendo &quot;enter&quot;"
          class="mr-30"
          :on-create="handleCreate"
        >
          <template #tag="{ option, handleTagRemove }">
            <div class="multiselect-tag" @click="handleTagRemove(option, $event)">
              <span>{{ option.value }}</span>
              <span class="multiselect-tag__remove" @click="handleTagRemove(option, $event)">x</span>
            </div>
          </template>
        </Multiselect>
        <p v-show="createErrorMessage" class="error-input">
          {{ createErrorMessage }}
        </p>
      </div>

      <div class="checkboxes">
        <div class="material-checkbox">
          <input id="checkbox" v-model="accountData.indeed_integration" type="checkbox" :value="accountData.indeed_integration">
          <label for="checkbox">Integrazione indeed</label>
        </div>
        <div v-if="accountData.indeed_integration" class="group group-50">
          <input
            id="indeed_company_email"
            v-model="accountData.indeed_company_email"
            autocomplete="new-password"
            type="text"
            class="inputMaterial"
            @blur="validateEmail(accountData.indeed_company_email, 'indeed_company_email')"
          >
          <span class="bar" />
          <label>Indeed Email</label>
          <p v-show="checkEmails.indeed_company_email" class="error-text">
            {{ checkEmails.indeed_company_email }}
          </p>
        </div>
      </div>
      <div v-if="accountData.contracttype != 1" class="material-checkbox">
        <input id="multi_store" v-model="accountData.multi_store" type="checkbox" :value="accountData.multi_store">
        <label for="multi_store" style="min-width: max-content;">Premium Retail Recruiting Model</label>
      </div>
      <div v-if="accountData.contracttype != 1" class="group group-50">
        <input
          id="num_max_recruiters"
          v-model="accountData.num_max_recruiters"
          autocomplete="off"
          type="number"
          class="inputMaterial"
        >
        <span class="bar" />
        <label>Recruiters Soft Limit Counter</label>
      </div>

      <div v-if="accountData.contracttype != 1" class="group group-100">
        <div class="material-checkbox">
          <input id="typeform" v-model="accountData.typeform" type="checkbox" :value="accountData.typeform">
          <label for="typeform">Typeform</label>
        </div>
      </div>
      <div v-if="accountData.contracttype != 1" class="group group-100">
        <div class="material-checkbox">
          <input id="service" v-model="accountData.multibrand" type="checkbox" :value="accountData.multibrand">
          <label for="service">Multibrand</label>
        </div>
      </div>

      <div v-if="accountData.contracttype != 1" class="material-checkbox">
        <input
          id="service"
          v-model="accountData.service"
          type="checkbox"
          :value="accountData.service"
        >
        <label for="service">Service</label>
      </div>

      <div v-if="accountData.contracttype != 1 && accountData.service" class="group group-50">
        <div
          class="material-checkbox"
          style="padding-right: 50px"
        >
          <input
            id="briefcollectiontypeform"
            v-model="accountData.briefcollectiontypeform"
            :disabled="!accountData.service"
            type="checkbox"
            :value="accountData.briefcollectiontypeform"
          >
          <label
            for="briefcollectiontypeform"
            style="min-width: max-content"
          >Invia questionario per dati aziendali</label>
        </div>
      </div>
      <!-- v-if="accountData.briefcollectiontypeform && accountData.contracttype != 1" -->

      <div v-if="accountData.briefcollectiontypeform && accountData.contracttype != 1" class="group group-50">
        <input
          id="contactemail"
          v-model="accountData.contactemail"
          :disabled="!accountData.briefcollectiontypeform"
          autocomplete="new-password"
          type="text"
          class="inputMaterial"
          @blur="validateEmail(accountData.contactemail, 'contactemail')"
        >
        <span class="bar" />
        <label>Email del referente *</label>
        <p v-show="checkEmails.contactemail" class="error-text">
          {{ checkEmails.contactemail }}
        </p>
        <p v-show="(checked === 1) && !accountData.contactemail" class="error-input">
          Campo obbligatorio.
        </p>
      </div>

      <div v-if="accountData.briefcollectiontypeform && accountData.contracttype != 1" class="group group-25">
        <input
          id="contactfirstname"
          v-model="accountData.contactfirstname"
          autocomplete="new-password"
          type="text"
          class="inputMaterial"
        >
        <span class="bar" />
        <label>Nome del referente *</label>
        <p v-show="(checked === 1) && !accountData.contactfirstname" class="error-input">
          Campo obbligatorio.
        </p>
      </div>

      <div v-if="accountData.briefcollectiontypeform && accountData.contracttype != 1" class="group group-25">
        <input
          id="contactsecondname"
          v-model="accountData.contactsecondname"
          autocomplete="new-password"
          type="text"
          class="inputMaterial"
        >
        <span class="bar" />
        <label>Cognome del referente *</label>
        <p v-show="(checked === 1) && !accountData.contactsecondname" class="error-input">
          Campo obbligatorio.
        </p>
      </div>

      <!-- end new -->
      <!-- Submit button -->
      <div style="width: 100%; text-align: right;  padding-bottom: 50px;">
        <material-button
          text="Crea azienda"
          type="success"
          :class="{ 'btn-disabled': isDisabled }"
          @click="createAccount()"
        />
      </div>
    </form>
  </div>
</template>

<script>

import dayjs from 'dayjs'
import { ref, reactive, onBeforeMount, nextTick, inject, computed } from 'vue'
import { api } from '@/api'
import { navbarStore } from '@/stores/navbar'
import { spinnerStore } from '@/stores/spinner'
import i18countries from 'i18n-iso-countries'
import 'i18n-iso-countries/langs/it.json'
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Multiselect
  },
  setup() {
    const activeBar = ref(null)
    const toast = inject('$toast')
    const navbar = navbarStore()
    const spinner = spinnerStore()
    const startDate = ref(null)
    const endDate = ref(null)
    const tags= reactive([])
    let checked = ref(0)
    const regions = [
      'Abruzzo',
      'Basilicata',
      'Calabria',
      'Campania',
      'Emilia-Romagna',
      'Friuli Venezia Giulia',
      'Lazio',
      'Liguria',
      'Lombardia',
      'Marche',
      'Molise',
      'Piemonte',
      'Puglia',
      'Sardegna',
      'Sicilia',
      'Toscana',
      'Trentino-Alto Adige',
      'Umbria',
      'Valle d\'Aosta',
      'Veneto'
    ]
    const checkEmails = reactive({
      email: '',
      indeed_company_email: '',
      contactemail: ''
    })
    const checkUrls = reactive({
      website: '',
      corporate_video_url: ''
    })
    const accountData = reactive({
      locale: 'it',
      //company
      cover_file: './cover-placeholder-large.png',
      logo_file: './logo-placeholder.png',
      co_name: '',
      co_description: '',
      address: '',
      city: '',
      postal_code: '',
      region_state: '',
      country: 'Italia',
      country_code: '',
      website: '',
      corporate_video_url: '',
      privacy_policy_url: '',

      // team
      team_name: '',
      email: '',
      first_name: '',
      last_name: '',
      //

      contracttype: 1,
      unlock_discount_reserve: null,
      contract_start_date: null,
      contract_end_date:null,
      contract_alert_emails: [],
      indeed_integration: false,
      indeed_company_email: '',
      multi_store:false,
      num_max_recruiters: 100,
      typeform: false,
      multibrand: false,
      service: false,
      briefcollectiontypeform: false,
      contactemail: '',
      contactfirstname: '',
      contactsecondname: '',
      requester: ''
    })

    const languages = [
      { label: 'Italiano', value: 'it' },
      { label: 'Inglese', value: 'en' },
      { label: 'Francese', value: 'fr' },
      { label: 'Spagnolo', value: 'es' },
      { label: 'Tedesco', value: 'de' },
      { label: 'Portoghese', value: 'pt' }
    ]
    const contracts = [
      { label: 'Freemium', value: 1 },
      { label: 'Pro', value: 0 }
    ]
    const coupons = [
      { label: 'Nessuno sconto', value: null },
      { label: '50%', value: '50%' },
      { label: '70%', value: '70%' },
      { label: '80%', value: '80%' },
      { label: '100%', value: '100%' }
    ]

    // Get country names
    i18countries.registerLocale(require('i18n-iso-countries/langs/it.json'))
    const countries = Object.values(i18countries.getNames('it')).map(name => ({ label: name, value: name }))

    onBeforeMount(() => {
      spinner.show()
      nextTick(() => {
        loadCanvasImage(canvasLogo, accountData.logo_file, 'logo')
        loadCanvasImage(canvasCover, accountData.cover_file, 'cover')
        navbar.title = 'Gestione Aziende'
        spinner.hide()
      })
    })
    //
    const canvasLogo = ref(null)
    const canvasCover = ref(null)
    const triggerFileUpload = (inputId) => {
      const fileInput = document.getElementById(inputId)
      if (fileInput) {
        fileInput.click()
      }
    }
    const scaleAndCropImage = (img, targetWidth, targetHeight) =>{

      let widthFactor = targetWidth / img.width
      let heightFactor = targetHeight / img.height
      var scaleFactor = 1.0

      let scaledWidth = img.width
      let scaledHeight = img.height

      // var imgX = 0.0;
      // var imgY = 0.0;

      if (widthFactor !== 1.0 || heightFactor !== 1.0) {
        if (widthFactor > heightFactor) { scaleFactor = widthFactor } // scale to fit height
        else { scaleFactor = heightFactor } // scale to fit width

        scaledWidth = img.width * scaleFactor
        scaledHeight = img.height * scaleFactor

        // if (widthFactor > heightFactor) { imgY = (targetHeight - scaledHeight) * 0.5 }
        // else if (widthFactor < heightFactor) { imgX = (targetWidth - scaledWidth) * 0.5 }
      }

      var imgCV = document.createElement('canvas')
      imgCV.width = targetWidth / scaleFactor
      imgCV.height = targetHeight / scaleFactor
      let paddingLeft = (imgCV.width - img.width) / 2
      let paddingTop = (imgCV.height - img.height) / 2

      img.crossOrigin = 'anonymous'

      // draw
      var imgCtx = imgCV.getContext('2d')
      imgCtx.fillStyle = '#fff' /// set white fill style
      imgCtx.fillRect(0, 0, imgCtx.width, imgCtx.height) // fill trasparent png bg in whithe, otherwise it will be black on jpg export
      imgCtx.drawImage(img, paddingLeft, paddingTop, img.width, img.height)

      if (scaleFactor === 1.0) {
        return imgCV
      } else if (scaleFactor < 1.0) {
        return downScaleCanvas(imgCV, scaleFactor)
      } else if (scaleFactor > 1.0) {
        imgCtx.clearRect(0, 0, imgCtx.width, imgCtx.height)
        imgCtx.scale(scaleFactor, scaleFactor)
        imgCtx.fillRect(0, 0, imgCtx.width, imgCtx.height) // fill trasparent png bg in whithe, otherwise it will be black on jpg export
        imgCtx.setTransform(1, 0, 0, 1, 0, 0)
        paddingLeft = (imgCtx.width - img.width) / 2
        paddingTop = (imgCtx.height - img.height) / 2
        imgCtx.drawImage(img, paddingLeft, paddingTop, scaledWidth, scaledHeight)
        return imgCV
      }
    }

    const downScaleCanvas = (cv, scale) => {
      if (scale <= 0 || scale >= 1) throw new Error('Scale must be a positive number < 1')

      const sw = cv.width
      const sh = cv.height
      const tw = Math.floor(sw * scale)
      const th = Math.floor(sh * scale)

      const sBuffer = cv.getContext('2d').getImageData(0, 0, sw, sh).data
      const tBuffer = new Float32Array(3 * tw * th)

      for (let sy = 0; sy < sh; sy++) {
        const ty = sy * scale
        const tY = Math.floor(ty)
        const yIndex = 3 * tY * tw
        const crossY = tY !== Math.floor(ty + scale)
        const wy = crossY ? (tY + 1 - ty) : 1
        const nwy = crossY ? (ty + scale - tY - 1) : 0

        for (let sx = 0; sx < sw; sx++) {
          const tx = sx * scale
          const tX = Math.floor(tx)
          const tIndex = yIndex + tX * 3
          const crossX = tX !== Math.floor(tx + scale)
          const wx = crossX ? (tX + 1 - tx) : 1
          const nwx = crossX ? (tx + scale - tX - 1) : 0

          const sIndex = (sy * sw + sx) * 4
          const sR = sBuffer[sIndex]
          const sG = sBuffer[sIndex + 1]
          const sB = sBuffer[sIndex + 2]

          if (!crossX && !crossY) {
            tBuffer[tIndex] += sR * scale * scale
            tBuffer[tIndex + 1] += sG * scale * scale
            tBuffer[tIndex + 2] += sB * scale * scale
          } else if (crossX && !crossY) {
            const w = wx * scale
            tBuffer[tIndex] += sR * w
            tBuffer[tIndex + 1] += sG * w
            tBuffer[tIndex + 2] += sB * w
            const nw = nwx * scale
            tBuffer[tIndex + 3] += sR * nw
            tBuffer[tIndex + 4] += sG * nw
            tBuffer[tIndex + 5] += sB * nw
          } else if (crossY && !crossX) {
            const w = wy * scale
            tBuffer[tIndex] += sR * w
            tBuffer[tIndex + 1] += sG * w
            tBuffer[tIndex + 2] += sB * w
            const nw = nwy * scale
            tBuffer[tIndex + 3 * tw] += sR * nw
            tBuffer[tIndex + 3 * tw + 1] += sG * nw
            tBuffer[tIndex + 3 * tw + 2] += sB * nw
          } else {
            const w = wx * wy
            tBuffer[tIndex] += sR * w
            tBuffer[tIndex + 1] += sG * w
            tBuffer[tIndex + 2] += sB * w
            const nw1 = nwx * wy
            tBuffer[tIndex + 3] += sR * nw1
            tBuffer[tIndex + 4] += sG * nw1
            tBuffer[tIndex + 5] += sB * nw1
            const nw2 = wx * nwy
            tBuffer[tIndex + 3 * tw] += sR * nw2
            tBuffer[tIndex + 3 * tw + 1] += sG * nw2
            tBuffer[tIndex + 3 * tw + 2] += sB * nw2
            const nw3 = nwx * nwy
            tBuffer[tIndex + 3 * tw + 3] += sR * nw3
            tBuffer[tIndex + 3 * tw + 4] += sG * nw3
            tBuffer[tIndex + 3 * tw + 5] += sB * nw3
          }
        }
      }

      const resCV = document.createElement('canvas')
      resCV.width = tw
      resCV.height = th
      const resCtx = resCV.getContext('2d')
      const imgRes = resCtx.getImageData(0, 0, tw, th)
      const tByteBuffer = imgRes.data

      for (let i = 0; i < tBuffer.length / 3; i++) {
        tByteBuffer[i * 4] = Math.min(255, Math.round(tBuffer[i * 3]))
        tByteBuffer[i * 4 + 1] = Math.min(255, Math.round(tBuffer[i * 3 + 1]))
        tByteBuffer[i * 4 + 2] = Math.min(255, Math.round(tBuffer[i * 3 + 2]))
        tByteBuffer[i * 4 + 3] = 255 // Alpha channel
      }

      resCtx.putImageData(imgRes, 0, 0)
      return resCV
    }
    const loadCanvasImage = (canvasRef, imageSrc, w) => {
      spinner.show()
      const canvas = canvasRef.value
      const ctx = canvas.getContext('2d')
      const img = new Image()

      img.onload = () => {
        const scaledCanvas = scaleAndCropImage(img, canvas.width, canvas.height)
        ctx.drawImage(scaledCanvas, 0, 0)
        const base64Image = canvas.toDataURL('image/png')
        if (w === 'logo') {
          accountData.logo_file = base64Image
        } else if (w === 'cover'){
          accountData.cover_file = base64Image
        }
        spinner.hide()
      }

      img.src = imageSrc
    }

    const uploadImage = (event, canvasRef, w) => {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.onload = (e) => {
        const img = new Image()
        img.onload = () => {
          const canvas = canvasRef.value
          const ctx = canvas.getContext('2d')

          // Usa scaleAndCropImage per scalare e ritagliare l'immagine
          const scaledCanvas = scaleAndCropImage(img, canvas.width, canvas.height)
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(scaledCanvas, 0, 0)

          // Converti il canvas in un'immagine base64
          const base64Image = canvas.toDataURL('image/png')
          if (w === 'logo') {
            accountData.logo_file = base64Image
          } else if (w === 'cover'){
            accountData.cover_file = base64Image
          }
        }
        img.src = e.target.result
      }

      reader.readAsDataURL(file)
    }

    const uploadLogo = (event) => {
      uploadImage(event, canvasLogo, 'logo')
    }

    const uploadCover = (event) => {
      uploadImage(event, canvasCover, 'cover')
    }
    //
    const isDisabled = computed(() => {
      const mandatoryFields = [
        'co_name',
        'co_description',
        'address',
        'city',
        'postal_code',
        'region_state',
        'country',
        'website',
        'team_name',
        'email',
        'first_name',
        'last_name',
        'contract_start_date',
        'contract_end_date'
      ]
      const isFieldEmpty = (value) => value === ''
      const missingFields = mandatoryFields.filter(field => isFieldEmpty(accountData[field]))

      //  Aggiungi i campi di indeed se abilitato
      if (accountData.indeed_integration) {
        ['indeed_company_email'].forEach(field => {
          if (isFieldEmpty(accountData[field])) {
            missingFields.push(field)
          }
        })
      }
      // Aggiungi i campi di briefcollectiontypeform se è abilitato
      if (accountData.briefcollectiontypeform) {
        ['contactemail', 'contactfirstname', 'contactsecondname'].forEach(field => {
          if (isFieldEmpty(accountData[field])) {
            missingFields.push(field)
          }
        })
      }
      return missingFields.length > 0
    })
    const clearInput = () => {
      checked.value = 0
      startDate.value = null
      endDate.value = null
      Object.assign(accountData, {
        logo_file: './logo_placeholder.png',
        cover_file:  './cover_placeholder.png',
        co_name: '',
        co_description: '',
        address: '',
        city: '',
        postal_code: '',
        region_state: '',
        country: 'Italia',
        country_code: '',
        locale: 'it',
        website: '',
        corporate_video_url: '',
        privacy_policy_url: '',
        team_name: '',
        email: '',
        first_name: '',
        last_name: '',
        contracttype: 1,
        unlock_discount_reserve: null,
        indeed_integration: false,
        indeed_company_email: '',
        typeform: false,
        multibrand: false,
        service: false,
        briefcollectiontypeform: false,
        contactemail: '',
        contactfirstname: '',
        contactsecondname: '',
        contract_start_date: null,
        contract_end_date:null,
        contract_alert_emails: [],
        multi_store:false,
        num_max_recruiters: 100
      })
      loadCanvasImage(canvasLogo, accountData.logo_file, 128, 128)
      loadCanvasImage(canvasCover, accountData.cover_file, 946, 326)
    }
    const validateUrls = (url, field) =>{
      let reason = ''
      console.log('isValidUrl', isValidUrl(url))
      if (url !== '' && !isValidUrl(url)) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          reason = 'deve iniziare con "http://" o "https://"'
        } else {
          reason = 'non segue il formato corretto'
        }
        checkUrls[field] = `L'URL "${url}" non è valido: ${reason}. Inserire un URL corretto.`
      } else {
        checkUrls[field] = ''
      }
    }
    const validateEmail = (email, field) =>{
      let reason = ''

      if (email !== '' && !isValidMail(email)) {
        if (!email.includes('@')) {
          reason = 'manca il simbolo "@"'
        } else if (!email.split('@')[1].includes('.')) {
          reason = 'manca un dominio valido (es: .com, .it)'
        } else {
          reason = 'non segue il formato corretto'
        }
        checkEmails[field] = `L'email "${email}" non è valida: ${reason}. Inserire un'email corretta.`
      } else {
        checkEmails[field] = ''
      }
    }
    const isValidUrl = (url) => {
      const re = /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|localhost|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[^\s]*)?$/i
      return re.test(url)
    }
    const isValidMail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    const checkValues = () => {
      const mandatoryFieldsMissing = []
      if (accountData.co_name === '') {
        mandatoryFieldsMissing.push('Ragione Sociale')
      }
      if (accountData.co_description=== '') {
        mandatoryFieldsMissing.push('Descrizione')
      }

      if (accountData.address === '') {
        mandatoryFieldsMissing.push('Indirizzo')
      }

      if (accountData.city === '') {
        mandatoryFieldsMissing.push('Città')
      }

      if (accountData.postal_code === '') {
        mandatoryFieldsMissing.push('Cap')
      }

      if (accountData.region_state === '') {
        mandatoryFieldsMissing.push('Regione')
      }

      if (accountData.country === '') {
        mandatoryFieldsMissing.push('Paese')
      }

      if (accountData.website === '') {
        mandatoryFieldsMissing.push('Website')
      }

      //Team

      if (accountData.team_name === '') {
        mandatoryFieldsMissing.push('Nome Team')
      }

      if (accountData.email === '') {
        mandatoryFieldsMissing.push('Email')
      }

      if (accountData.first_name === '') {
        mandatoryFieldsMissing.push('Nome')
      }

      if (accountData.last_name === '') {
        mandatoryFieldsMissing.push('Cognome')
      }

      if (accountData.contract_start_date === '') {
        mandatoryFieldsMissing.push('Data inizio contratto')
      }
      if (accountData.contract_end_date === '') {
        mandatoryFieldsMissing.push('Data fine contratto')
      }
      // Verifica i campi obbligatori se indeed è abilitato
      if (accountData.indeed_integration) {
        if (accountData.indeed_company_email === '') {
          mandatoryFieldsMissing.push('Email Indeed')
        }
      }
      // Verifica i campi obbligatori se briefcollectiontypeform è abilitato
      if (accountData.briefcollectiontypeform) {
        if (accountData.contactemail === '') {
          mandatoryFieldsMissing.push('Email del referente')
        }
        if (accountData.contactfirstname === '') {
          mandatoryFieldsMissing.push('Nome del referente')
        }
        if (accountData.contactsecondname === '') {
          mandatoryFieldsMissing.push('Cognome del referente')
        }
      }
      if (mandatoryFieldsMissing.length > 0) {
        const mandatoryFieldsList = mandatoryFieldsMissing.join(', ')
        if (mandatoryFieldsMissing.length === 0) {
          return `<p>Il campo ${mandatoryFieldsList} è obbligatorio</p>`
        } else {
          return `</p>I seguenti campi sono obbligatori:</p> ${mandatoryFieldsList}`
        }
      }
      return null
    }

    const estraiBase64 = (dataUrl) =>{
      const match = dataUrl.match(/^data:image\/[^;]+;base64,(.*)$/)
      if (match) {
        return match[1]
      }
      return null
    }

    const createAccount = async () => {

      checked.value = 1
      // Aggiorna i dati dell'account con le informazioni aggiuntive
      accountData.country_code = i18countries.getAlpha2Code(accountData.country, 'it')
      accountData.requester = accountData.co_name
      accountData.contract_start_date = startDate.value ? dayjs(startDate.value).format('YYYY-MM-DD') : undefined
      accountData.contract_end_date = endDate.value ? dayjs(endDate.value).format('YYYY-MM-DD') : undefined

      // Verifica se tutti i campi obbligatori sono compilati
      const mandatoryFieldsFilled = checkValues()
      if (mandatoryFieldsFilled) {
        toast.warning(
          '<b>Non è stato possibile inviare la richiesta di creazione.</b>' +
          mandatoryFieldsFilled
        )
        return
      }

      // Verifica la validità delle email e degli URL
      if ((accountData.email && !isValidMail(accountData.email)) ||
      (accountData.indeed_company_email && !isValidMail(accountData.indeed_company_email))) {
        toast.warning('Indirizzo e-mail non valido')
        return
      }

      if (accountData.website && !isValidUrl(accountData.website)) {
        toast.warning('Indirizzo URL del website non valido')
        return
      }

      if (accountData.corporate_video_url && !isValidUrl(accountData.corporate_video_url)) {
        toast.warning('Indirizzo URL del corporate video non valido')
        return
      }

      // Crea una copia non referenziata di accountData
      const clonedAccountData = JSON.parse(JSON.stringify(accountData))

      // Converti i file in Base64 nella copia clonata
      clonedAccountData.cover_file = clonedAccountData.cover_file ? estraiBase64(clonedAccountData.cover_file) : ''
      clonedAccountData.logo_file = clonedAccountData.logo_file ? estraiBase64(clonedAccountData.logo_file) : ''

      try {
        spinner.show()
        await api.post('/auth/add-new-business', clonedAccountData)
        checked.value = 0
        clearInput()
        toast.success('<b>Nuova azienda creata!</b><p>L\'azienda sarà visibile in "Lista aziende" solo dopo la conferma dell\'email dell\'amministratore.</p>')
      } catch (error) {
        checked.value = 2
        const el = document.getElementById('companyManagement')

        if (el) {
          el.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }

        toast.warning('Non è stato possibile creare l\'azienda: ' + error.response.data.error)
      } finally {
        spinner.hide()
      }
    }

    const createErrorMessage = ref('')
    const handleCreate = (newEmail) => {
      createErrorMessage.value = ''
      if (!isValidMail(newEmail.value)) {
        let reason = ''
        if (!newEmail.value.includes('@')) {
          reason = 'manca il simbolo "@"'
        } else if (!newEmail.value.split('@')[1].includes('.')) {
          reason = 'manca un dominio valido (es: .com, .it)'
        } else {
          reason = 'non segue il formato corretto'
        }
        createErrorMessage.value = `L'email "${newEmail.value}" non è valida: ${reason}. Inserire un'email corretta.`

        return false

      }
      if (accountData.contract_alert_emails.includes(newEmail.value)) {
        createErrorMessage.value = `L'email "${newEmail.value}" è già stata inserita `

        return false
      }
      return newEmail
    }

    const selectCountry = () => {
      if (accountData.country != 'Italia') {
        accountData.region_state = ''
      }
    }

    return {
      selectCountry,
      activeBar,
      accountData,
      languages,
      contracts,
      coupons,
      countries,
      isValidMail,
      createAccount,
      isDisabled,
      checkEmails,
      validateEmail,
      checked,
      checkUrls,
      validateUrls,
      startDate,
      endDate,
      tags,
      handleCreate,
      createErrorMessage,
      regions,
      uploadCover,
      uploadImage,
      uploadLogo,
      canvasLogo,
      canvasCover,
      triggerFileUpload
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
#companyManagement .scroll {
  margin-top: -20px;
}
.files-container-label {
    top:20px!important;
    font-size: 14px!important;
    left: 0px!important;
    color: var(--accented)!important;
    position: relative!important;
}
.files-container {
  position: relative;
  width: 100%;
  height: auto; /* Garantisce che l'altezza sia adeguata per contenere il contenuto */
}

.logo-container {
  position: absolute;
  top: 36px;
  left: 20px;
  width: 128px;
  height: 128px;
  z-index: 10;
  overflow: hidden;
  .uploadLogo {
    border-radius: 6px;
    cursor: pointer;
    position: absolute;
    height: 0;
    width: 100% ;
    bottom: 0;
    font-size: 0;
    display:flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 8%;
    text-transform: uppercase;
    font-weight: 800;
  }
  &:hover {
    .uploadLogo {
    font-size: 14px;
    border: 1px solid #1b72a0;
    height: 100%;
    width: 100%;
  }
  }
}

.cover-container {
  position: relative;
  width: 100%;
  .uploadCover {
    border-radius: 6px;
    cursor: pointer;
    position: absolute;
    height: 0;
    width: 100% ;
    bottom: 0;
    font-size: 0;
    display:flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 5%;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0;
  }
  &:hover {
    .uploadCover {
    font-size: 20px;
    border: 1px solid #1b72a0;
    height: 100%;
    width: 100%;
  }
  }
}

.file-input {
  display: none;
}

.logo-canvas {
  border-radius: 6px;
  width: 128px;
  height: 128px;
  cursor: pointer;
}

.cover-canvas {
  border-radius: 6px;
  min-width: 100%;
  width: 100%;
  height: auto;
  min-height: 326px;
  aspect-ratio: 946 / 326; /* Mantiene l'aspect ratio corretto */
  cursor: pointer;
  border: 0;
}
.file-input {
  display: none;
}

.logo-canvas {
  width: 128px;
  height: 128px;
}

.cover-canvas {
  width: 100%;
}
#companyManagement .multiselect-wrapper {
  height: 34px!important;
  min-height: 34px!important;
  max-height: 34px!important;
}
.multiselect-no-options {
  color: #ffffff95;
  font-size: 12px;
  font-style: italic;
}
  input.multiselect-search,
  .multiselect-search {
    background: transparent !important;
    background-color: transparent !important;
  }
  .flex-start {
    display: flex;
    align-items: stretch;
    .multiselect-wrapper {
      height: 100% !important;
      cursor: pointer !important;
    }
  }
  .multiselect {
    background: transparent;
    color: white;
    font-size: 14px;
    height: 34px !important;
    min-height: 34px !important;
    max-height: 34px !important;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: none !important;
    outline: none !important;
    &.search-active {
      flex: 1 !important;
      display: flex;
      height: unset !important;
      max-height: 100% !important;
      align-items: center;
      justify-content: center;

      .multi-label-container {
        height: 100%;
        .multi-label {
          margin: 5px;
          max-width: 60%;
        }
      }
      .multi-label-container {
        margin: 0;
        width: calc(100% - 40px) !important;
      }
    }

    input {
      background: transparent !important;
    }

    &.is-active {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .multiselect-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  .multiselect-dropdown {
    background: #004278;
    color: white;
    z-index: 999999;
    box-shadow: none !important;
    outline: none !important;
    max-height: calc(52.5px * 6);

    input {
      &:focus,
      &:focus-visible,
      &:hover {
        box-shadow: none !important;
        outline: none !important;
      }

      &::placeholder {
        color: white !important;
      }
    }
  }

  .multiselect-multiple-label span.label {
    font-size: 12px;
    color: white;
    background: var(--info);
    padding: 5px 10px;

    border-radius: 3px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  ul.multiselect-options li.multiselect-option {
    font-size: 14px;
    padding: 0 !important;
    min-height: 0 !important;

    span {
      padding: 10px 15px !important;
    }

    span.disabled {
      display: none;
    }
  }

  .multiselect-option.is-pointed {
    //background: rgba(16, 252, 224, 0.25) !important;
    background: linear-gradient(90deg, rgba(66, 186, 222, 0.2) 60%, rgba(2, 0, 36, 0) 100%) !important;
    color: white;
    position: relative;
  }

  .multiselect-caret {
    background: white;
    margin-right: 10px;
  }

  .multiselect-option.is-selected {
    background: #4c75997b !important;
    position: relative;
  }

  li.is-selected span.close {
    position: absolute !important;
    right: 5px;
    color: #b8cee0;
  }

  .multiselect-clear-icon {
    background: #b8cee0;
  }

  .multiselect .multiselect-clear:hover .multiselect-clear-icon {
    background: white;
  }

  .multiselect-tag__remove {
    color: white;
    padding: 0 1rem;
  }
  .multiselect input {
    color: white;
  }

.btn-disabled {
 background-color: #28a74540!important;
}
.group p:not(.error-input) {
  text-align: left;
}
.group p {
  text-align: left;
  font-size: 12px;
    padding-top: 1px;
    color: white;
}
.error-text,
.error-input {
    font-size: 10px!important;
    padding-top: 0px;
    color: #ffc107!important;
}
.error-input {
  text-align: right!important;
}
.v-select {
  color: white;
  padding: 0 !important;
  svg {
    fill: white;
  }
  button.vs__clear svg {
    fill: var(--accented);
  }
  .vs__dropdown-toggle {
    padding: 0;
    border: 0;
    .vs__selected-options {
      padding: 0;
      .vs__selected {
        color: white;
      }
    }
  }
}
.vs__dropdown-menu {
  background: #000000b5;
  padding: 0;
  color: white;
  top: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 3px;
  z-index: 30;
  position: absolute;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.15);
  li {
    padding: 5px 8px !important;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background: radial-gradient(circle at 50% 100%, #008bdb56, #076fd03a);
  }
}
.row-direction {
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  div {
    display: flex;
    flex: 1;
  }
}
.checkboxes {
  align-items: center;
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 25px 0;
  width: 100%;
}
.material-checkbox {
  height: 34px;
  min-width: calc(50% - 25px);
  position: relative;
  display: flex;
  flex-direction: row !important;
  flex: 1;
  input[type="checkbox"] {
    display: block !important;
    pointer-events: all !important;
  }
  label {
    color: white !important;
    left: 35px !important;
    pointer-events: all !important;
    top: 1px !important;
  }
}

input.multiselect-search,
  .multiselect-search {
    background: transparent !important;
    background-color: transparent !important;
  }
  .flex-start {
    display: flex;
    align-items: stretch;
    .multiselect-wrapper {
      height: 100% !important;
      cursor: pointer !important;
    }
  }
  .multiselect {
    background: transparent;
    color: white;
    height: 34px !important;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: none !important;
    outline: none !important;
    &.search-active {
      flex: 1 !important;
      display: flex;
      height: unset !important;
      max-height: 100% !important;
      align-items: center;
      justify-content: center;

      .multi-label-container {
        height: 100%;
        .multi-label {
          margin: 5px;
          max-width: 60%;
        }
      }
      .multi-label-container {
        margin: 0;
        width: calc(100% - 40px) !important;
      }
    }

    input {
      background: transparent !important;
    }

    &.is-active {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .multiselect-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  .multiselect-dropdown {
    background: #004278;
    color: white;
    z-index: 999999;
    box-shadow: none !important;
    outline: none !important;
    max-height: calc(52.5px * 6);

    input {
      &:focus,
      &:focus-visible,
      &:hover {
        box-shadow: none !important;
        outline: none !important;
      }

      &::placeholder {
        color: white !important;
      }
    }
  }

  .multiselect-multiple-label span.label {
    font-size: 12px;
    color: white;
    background: var(--info);
    padding: 5px 10px;

    border-radius: 3px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  ul.multiselect-options li.multiselect-option {
    font-size: 14px;
    padding: 0 !important;
    min-height: 0 !important;

    span {
      padding: 10px 15px !important;
    }

    span.disabled {
      display: none;
    }
  }

  .multiselect-option.is-pointed {
    //background: rgba(16, 252, 224, 0.25) !important;
    background: linear-gradient(90deg, rgba(66, 186, 222, 0.2) 60%, rgba(2, 0, 36, 0) 100%) !important;
    color: white;
    position: relative;
  }

  .multiselect-caret {
    background: white;
    margin-right: 10px;
  }

  .multiselect-option.is-selected {
    background: #4c75997b !important;
    position: relative;
  }

  li.is-selected span.close {
    position: absolute !important;
    right: 5px;
    color: #b8cee0;
  }

  .multiselect-clear-icon {
    background: #b8cee0;
  }

  .multiselect .multiselect-clear:hover .multiselect-clear-icon {
    background: white;
  }

  .multiselect-tag__remove {
    color: white;
    padding: 0 1rem;
  }
  .multiselect input {
    color: white;
  }
</style>

<style lang="scss" scoped>
@import "../../../assets/scss/lists.scss";
@media only screen and (max-width: 720px) {
  .row-direction {
    flex-direction: column !important;
  }
  .group-50,
  .group-100 {
    height: unset !important;
  }
  .group-100 .group-50 {
    min-width: 100% !important;
    width: 100%;
    margin: 0 0 25px 0 !important;
  }
}
.group-100 .group-50 .bar {
  width: 100%;
}
form {
  padding: 0!important;
  color: white;
}
.switch-container {
  display: flex;
  margin-top: -25px;
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    display: none;
  }

  .slider {
    transform: scale(0.7);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid white;
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider:before {
    background-color: var(--accented);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
form {
  overflow: scroll !important;
}
.container {
  display: block;
  height: 100%;
  overflow-x: hidden;
  max-height: 100vh;
  overflow-y: auto;
  padding: 12px 50px;
}

.title-form {
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: 35px 0 25px 0;
}
.group-50 .v-select {
  margin-top: -2px;
}
.group {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 35px;
}
.modalOptions {
  background: #000000b5;
  color: white;
  top: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 3px;
  z-index: 30;
  position: absolute;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.15);
  ul {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background: radial-gradient(circle at 50% 100%, #008bdb56, #076fd03a);
    li {
      padding: 8px 10px;
      text-align: left;
      border-bottom: 0;
      transition: all 1s ease-in-out;
      position: relative;
    }
    li:hover {
      color: var(--accented);
      background: linear-gradient(
        90deg,
        rgba(0, 255, 163, 0.05) 60%,
        rgba(2, 0, 36, 0)
      );
      font-weight: 600;
    }
  }
}
</style>
